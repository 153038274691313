<template style="background-color: white">
  <div id="app-question-add" class="app-add">
    <loading :loading="loading" text="提交中"></loading>
    <el-form ref="form" :model="question" :rules="rules"  label-width="100px">
      <major-form-item ref="majorFormItem"></major-form-item>
      <knowledge-form-item ref="knowledgeFormItem"></knowledge-form-item>
      <el-form-item label="法条">
        <select-tree-law ref="lawTree"></select-tree-law>
      </el-form-item>
      <source-form-item ref="sourceFormItem" @parentEvent="getSourceId" :model="question.sourceId" :parent="this" prop="sourceId" ></source-form-item>
      <el-form-item label="难易度">
        <select-difficult ref="selectDifficult" @parentEvent="getDifficultId"></select-difficult>
      </el-form-item>
      <el-form-item label="题干" prop="stem">
        <el-input type="textarea" :rows="4" v-model="question.stem" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="答案">
        <el-input type="textarea" :rows="4" v-model="question.answer" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="文字解析" prop="analysisText">
        <el-input type="textarea" :rows="4" v-model="question.analysisText" placeholder = "请输入"></el-input>
      </el-form-item>
      <el-form-item label="视频解析" prop="analysisVideo">
        <el-input type="number" v-model="question.analysisVideo" placeholder="百家云视频ID"></el-input>
      </el-form-item>
      <el-form-item label="考点" prop="examPoint">
        <el-input type="text" v-model="question.examPoint" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="得分点">
        <score-word ref="scoreWord"></score-word>
      </el-form-item>
      <el-form-item style="display: none">
        <el-button @click="submit('form')" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import SelectTreeLaw from "@/components/SelectTreeLaw";
import SourceFormItem from "@/components/SourceFormItem";
import KnowledgeFormItem from "@/components/KnowledgeFormItem";
import SelectDifficult from "@/components/SelectDifficult";
import {setBodyEvent} from "../../../../public/question/js/question-vue";
import {getLocalProjectId, request, TipEnum, UrlEnum,QuestionTypeEnum} from "../../../../public/js/common-vue";
import MajorFormItem from "@/components/MajorFormItem";
import ScoreWord from "@/components/ScoreWord";
import Loading from "@/components/Loading";

export default {
  name: "Edit",
  data(){
    return{
      question:{
        difficultId:'',
        typeId:'',
        stem:'',
        answer:'',
        analysisText:'',
        analysisVideo:0,
        scoreWords:[],
        subjectId:'',
        options:[],
        parentId:0,
        projectId:getLocalProjectId(),
        knowledgeIds:[],
        lawIds:[],
        sourceId:'',
        examPoint:'',
        majors:[],
        majorIds:[]
      },
      loading: false,
      rules:{
        sourceId :[{required: true, message:'请输入', trigger:'blur'}],
        stem :[{required: true, message:'请输入', trigger:'blur'}],
        analysisText: [{required:true,message:'请输入',trigger:'blur'}],
        analysisVideo: [{required:true,message:'请输入',trigger:'blur'}]
      },
    }
  },
  components: {Loading, SelectTreeLaw, SourceFormItem, KnowledgeFormItem,SelectDifficult,MajorFormItem,ScoreWord},
  methods:{
    initData(){
      this.$http({
        method: "get",
        url: UrlEnum.QUESTION + "/" + request('id'),
        data: this.question,
      })
          .then((res) => {
            this.question = res.data;
            console.log(JSON.stringify(this.question));
            this.$refs.knowledgeFormItem.initData(null,this.question.knowledges,this.question.subjectId);
            this.$refs.lawTree.initData(null,this.question.laws);
            this.$refs.sourceFormItem.initData(this.question.sourceId);
            this.$refs.selectDifficult.initData(null,this.question.difficultId);
            this.$refs.majorFormItem.initData(this.question.majors)
            this.$refs.scoreWord.initData(this.question.scoreWords);
          })
          .catch((res) => {
            this.loading = false;
            if(res.response != null){
              const data = res.response.data;
              this.showMsgError(data.message);
            }
          })
    },
    getSourceId(data){
      this.question.sourceId = data;
      console.log(data);
    },
    getDifficultId(data){
      this.question.difficultId = data;
      console.log(data)
    },
    submit: function (formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.question.knowledgeIds = this.$refs.knowledgeFormItem.getCheckedIds();
          this.question.lawIds = this.$refs.lawTree.getCheckedIds();
          this.question.majorIds = this.$refs.majorFormItem.getCheckedIds();
          this.question.scoreWords = this.$refs.scoreWord.getScoreWords();
          this.$http({
            method: "put",
            url: UrlEnum.QUESTION+"/"+request("id"),
            data: this.question,
          })
              .then((res) => {
                this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
                const index = parent.layer.getFrameIndex(window.name); //先得到当前iframe层的索引
                setTimeout(function () {
                  parent.layui.table.reload('tableData'); //重载表格
                  parent.layer.close(index); //再执行关闭
                }, 800);
              })
              .catch((res) => {
                this.loading = false;
                if(res.response != null){
                  const data = res.response.data;
                  this.showMsgError(data.message);
                }
              });
        }
      })
    },
  },
  mounted() {
    setBodyEvent(this);
    this.initData();
  },
}
</script>

<style scoped>
@import "../../../../static/css/add.css";
</style>
